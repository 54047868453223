export default function (Alpine) {
  Alpine.data("applicationFlowExpectation", () => ({
    open: false,
    subOpen: false,
    isValid: false,
    messages: {},
    selected: {
      id: "",
      title: "",
      description: "",
      icon: "",
    },
    selectedSub: null,
    selectedAmount: null,
    mainCategories: [],
    subCategories: [],
    initialMainCategory: null,
    initialSubCategory: null,
    hasInteracted: false,
    init() {
      if (this.initialMainCategory) {
        const [_, details] =
          this.mainCategories.find(
            ([catId]) => catId === this.initialMainCategory,
          ) || [];
        if (details) {
          this.selected = {
            id: this.initialMainCategory,
            title: details.title,
            description: details.description,
            icon: details.icon,
          };
          this.hasInteracted = true;
        }
      }

      if (!this.selected?.id && this.mainCategories.length > 0) {
        const [firstId, firstDetails] = this.mainCategories[0];
        this.selected = {
          id: firstId,
          title: firstDetails.title,
          description: firstDetails.description,
          icon: firstDetails.icon,
        };
      }

      if (this.initialSubCategory && this.selected?.id) {
        const categoryData = this.subCategories[this.selected.id];
        if (categoryData?.subcategories) {
          const [_, subCat] =
            categoryData.subcategories.find(
              ([id, _]) => id === this.initialSubCategory,
            ) || [];
          if (subCat) {
            this.selectedSub = {
              id: this.initialSubCategory,
              title: subCat.title,
              description: subCat.description || "",
            };
            this.hasInteracted = true;
          }
        }
      }

      this.validateForm();
    },

    selectOption(id) {
      const [_, details] =
        this.mainCategories.find(([catId]) => catId === id) || [];
      if (details) {
        this.selected = {
          id,
          title: details.title,
          description: details.description,
          icon: details.icon,
        };
      }

      this.selectedSub = null;
      this.open = false;
      if (id === "OTHER") {
        this.subOpen = false;
        const otherSubCategories = this.subCategories.OTHER?.subcategories;
        if (otherSubCategories?.length > 0) {
          const [firstId, firstSubCat] = otherSubCategories[0];
          this.selectedSub = {
            id: firstId,
            title: firstSubCat.title,
            description: firstSubCat.description || "",
          };
        }
      }
      this.validateForm();
    },

    selectSubOption(subCat) {
      if (!this.selected?.id) return;

      if (subCat) {
        this.selectedSub = {
          id: subCat.id,
          title: subCat.title,
          description: subCat.description || "",
        };
      }
      this.subOpen = false;
      this.validateForm();
    },

    validateForm() {
      const amountInput = document.querySelector(
        'input[name="expectations-expected_funding_amount_range"]:checked',
      );
      const hasAmount = Boolean(amountInput);
      const mainCat = this.selected?.id;

      if (mainCat === "OTHER") {
        this.isValid = Boolean(hasAmount) && Boolean(mainCat);
      } else {
        const subCat = this.selectedSub?.id;
        this.isValid =
          Boolean(hasAmount) && Boolean(mainCat) && Boolean(subCat);
      }
    },
  }));
}
